import React, { useRef, useState } from "react";
import { navigate } from "gatsby";

import * as styleSheet from "./index.module.scss"

const ServiceSelector = ({ services }) => {
	const selectRef = useRef();
	const [ error, setError ] = useState(false);

	const goToService = () => {
		setError(false);
		if( selectRef.current && selectRef.current.options.selectedIndex ){
			if( selectRef.current.options[selectRef.current.options.selectedIndex].value ){
				navigate(selectRef.current.options[selectRef.current.options.selectedIndex].value);
			} else {
				setError(true);
			}
		} else {
			setError(true);
		}
	}

  	return (
		<div className={styleSheet.ServiceSelector}>
			<span className={styleSheet.heading}>More services:</span>
			<div className={error ? styleSheet.selectcontainererror : styleSheet.selectcontainer}>
				<select ref={selectRef} aria-label="More services:">
					<option value={false}>Choose a service</option>
					{ services.map( (service, index) => {
						return <option key={service.id} value={service.link} aria-label={`Select ${service.title}`} dangerouslySetInnerHTML={{__html: service.title}}></option>
					})}
				</select>	
			</div>
			<button className={styleSheet.go} aria-label="Go to location" onClick={(event)=>{ goToService();  }}>Go</button>
		</div>
	);
}

export default ServiceSelector;
