import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import LocationSelector from "../components/locationSelector";
import ServiceSelector from "../components/serviceSelector";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import FAQAccordion from "../components/faqAccordion";

import * as styleSheet from "./service.module.scss"

const ServiceTemplate = (props) => {

	const {
		data: {
			service,
			service: {
				id: serviceId,
				title,
				ACFService: {
					serviceInfo: {
						left: {
							requestAppointmentLink,
							faqs,
							callout: {
								backgroundImage: calloutBG,
								blurb: calloutBlurb,
								heading: calloutHeading,
								link: calloutLink
							}
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							serviceContent: content
						}
					}
				}
			},
			locations: {
				nodes: serviceLocations
			},
			services: {
				nodes: services
			},
			home: {
				ACFHomePage: {
					hero: {
						callouts: {
							callout1: {
								heading: homeCalloutHeading,
								blurb: homeCalloutBlurb,
								link: homeCalloutLink,
							}
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			rightArrowYellow,
			arrowBlue,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage,
			calloutBG: genericCalloutBG
		}
	} = props;

	const locations = serviceLocations.filter((location, index) => {

		if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.right.allServices ){
			const serviceIds = location.ACFLocation.locationInfo.right.allServices.map(service =>
				`${service.id}`,
			);

			return serviceIds && serviceIds.includes(serviceId);
		} else {
			return null;
		}
	});

	return (
		<Layout>
			<Seo post={service} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.servicename} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						<span className={styleSheet.serviceheading}>This service is offered at the following locations:</span>
						{ locations && locations.length > 0 && <LocationSelector locations={locations} /> }
						<div className={styleSheet.locationlist}>
							<div className={styleSheet.inner}>
								<ul className={styleSheet.list}>
									{ locations && locations.map((location, index) => {
										return (
											<li key={`lstloc-'${location.id}`} className={styleSheet.listitem}>
												<Link to={location.link} className={styleSheet.link}>
													<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
													<span dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}` }}></span>
												</Link>	
											</li>
										)
									})}
								</ul>
							</div>
						</div>
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styleSheet.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styleSheet.request}>Request appointment</Link> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.servicename} dangerouslySetInnerHTML={{__html: title}}></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<ServiceSelector services={services} />
					{ faqs && <FAQAccordion faqs={faqs} /> }
					<div className={styleSheet.callout}>
						<div className={styleSheet.left}>
							{ (genericCalloutBG || calloutBG) && <FluidImage image={calloutBG ? calloutBG : genericCalloutBG} passedClass={styleSheet.bg} /> }
						</div>
						<div className={styleSheet.right}>
							{ (calloutHeading || homeCalloutHeading) && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading ? calloutHeading : homeCalloutHeading }}></div> }
							{ (calloutBlurb || homeCalloutBlurb) && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: calloutBlurb ? calloutBlurb : homeCalloutBlurb}}></div> }
							{ (homeCalloutLink || calloutLink) && <WPLink link={calloutLink ? calloutLink : homeCalloutLink} passedClass={styleSheet.link} /> }
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.servicecontent}>
						<div className={styleSheet.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  service: wpService(id: {eq: $id}) {
    id
    title
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFService {
      serviceInfo {
        left {
          requestAppointmentLink {
            title
            url
            target
          }
          faqs {
            ... on WpFaq {
              id
              ACFFaq {
                faqAnswer
              }
              title
            }
          }
          callout {
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
                publicURL
                extension
              }
            }
            blurb
            heading
            link {
              target
              title
              url
            }
          }
        }
        right {
          introContent {
            blurb
            heading
            link {
              target
              title
              url
            }
          }
          serviceContent
        }
      }
    }
  }
  services: allWpService(sort: {fields: title}, filter: {id: {ne: $id}}) {
    nodes {
      title
      link
      id
    }
  }
  locations: allWpLocation(sort: {fields: title}) {
    nodes {
      title
      id
      link
      ACFLocation {
        menuTitle
        locationInfo {
          right {
            allServices {
              ... on WpService {
                id
              }
            }
          }
        }
      }
			birdeyeLocation {
				location {
					city
					state
				}
			}
    }
  }
  home: wpPage(isFrontPage: {eq: true}) {
    ACFHomePage {
      hero {
        callouts {
          callout1 {
            heading
            blurb
            link {
              target
              title
              url
            }
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  teamPage: allWpPage(filter: {template: {templateName: {eq: "Team Page"}}}) {
    nodes {
      link
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "servicebg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "servicebg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "servicebg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "servicebg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  calloutBG: file(name: {eq: "callout_overlay_girl"}) {
    childImageSharp {
      gatsbyImageData(width: 295, layout: CONSTRAINED)
    }
  }
}
`;

export default ServiceTemplate;
